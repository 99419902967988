export const tags = [
  {
    name: 'acne',
    value: 'Acne',
  },
  {
    name: 'dark_circles',
    value: 'Dark Circles',
  },
  {
    name: 'spots',
    value: 'Spots',
  },
  {
    name: 'eye_concerns',
    value: 'Eye Concerns',
  },
  {
    name: 'moisture',
    value: 'Moisture'
  },
  {
    name: 'firmness',
    value: 'Firmness',
  },
  {
    name: 'oiliness',
    value: 'Oiliness',
  },
  {
    name: 'pores',
    value: 'Pores',
  },
  {
    name: 'radiance',
    value: 'Radiance',
  },
  {
    name: 'redness',
    value: 'Redness',
  },
  {
    name: 'texture',
    value: 'Texture',
  },
  {
    name: 'wrinkles',
    value: 'Wrinkles',
  },
  {
    name: 'pre_procedure',
    value: 'Pre Procedure',
  },
  {
    name: 'post_procedure',
    value: 'Post Procedure',
  },
  {
    name: 'body',
    value: 'Body',
  },
  {
    name: 'neck_decolette',
    value: 'Neck + Decolette',
  },
  {
    name: 'lips',
    value: 'Lips',
  },
  {
    name: 'hands',
    value: 'Hands',
  }
];