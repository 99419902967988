<template>
  <div class="content-wrap">
    <Notifications />

    <h2>Shopify Product Activity</h2>
    <table class="table table-box table-striped product-logs-table">
      <thead>
        <tr>
          <th>Shopify Product Id</th>
          <th>Field Name</th>
          <th>Old Value</th>
          <th>New Value</th>
          <th>Changed At</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="productLogs.length < 1">
          <td colspan="4" class="text-center pt-3"> No logs found. </td>
        </tr>
        <tr
          v-for="log in productLogs"
          :key="log.id"
          class="hover-btn"
        >
          <td>{{ log.shopify_product_id }}</td>
          <td>{{ log.field_name }}</td>
          <td>{{ log.old_value }}</td>
          <td>{{ log.new_value }}</td>
          <td>{{ log.changed_at }}</td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :total-rows="pagination.totalRows"
      :total-pages="pagination.totalPages"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      @change-per-page="changePerPage"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { encodeQueryData } from '@/helper';
import { ProductLogsService } from '@/services/api.service';
import Pagination from '@/views/dashboard/partials/Paginations';

// @vuese
// @group MyAccount
export default {
  name: 'ShopifyProductLog',
  components: {
    Pagination
  },

  data() {
    return {
      productLogs: [],
      pagination: {
        totalPages: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 20
      },
    };
  },
  computed: {
    ...mapGetters([
      'currentUser', 'physicians'
    ])
  },

  beforeMount() {
    this.fetchLogs(this.logParams());
  },

  methods: {
    fetchLogs() {
      if (this.sending) return;

      this.sending = true;

      return ProductLogsService.getProductLogs(
        this.logParams()
      ).then(this.setLogs)
        .finally(() => this.sending = false);
    },

    logParams() {
      return encodeQueryData(
        this.buildFilterObject()
      );
    },

    buildFilterObject() {
      return {
        page: this.pagination.currentPage,
        per: this.pagination.perPage
      };
    },
    setLogs({ data }) {
      this.productLogs = data.shopify_product_change_logs.items;
      this.pagination.totalPages = data.shopify_product_change_logs.total_pages;
      this.pagination.totalRows = data.shopify_product_change_logs.total_items;
    },
    changePage(value) {
      this.pagination.currentPage = value;
      this.fetchLogs(this.logParams());
    },
    changePerPage(value) {
      this.pagination.perPage = value;
      this.pagination.currentPage = 1;
      this.fetchLogs(this.logParams());
    },
  }
};
</script>