import Vue from 'vue';
import axios from 'axios';
import {getToken, destroyToken} from './jwt.service';
import { API_URL, shopifyURL } from './config';
import VueAxios from 'vue-axios';

const ApiService = {
  prefixUrl: 'practice',
  practiceId: '',
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  setBaseUrl(baseURL) {
    Vue.axios.defaults.baseURL = baseURL;
  },

  setPrefixUrl(prefix) {
    this.prefixUrl = prefix;
  },

  setPracticeId(practiceId) {
    this.practiceId = practiceId;
  },

  setInterceptors() {
    Vue.axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          LoginService.logOut()
            .then(() => {
              destroyToken();
              if (this.$route.name != 'Login') {
                this.$router.push({name: 'Login'});
                this.flashWarning('Your session has expired');
              }
            });
        }
        return Promise.reject({ ...error });
      }
    );
  },

  setHeader() {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
  },

  get(resource, params = null) {
    const url = params ? `${resource}?${params}` : resource;
    return Vue.axios
      .get(url)
      .catch(error => {
        throw new Error(`[RWV] ApiService ${error}`);
      });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource, params) {
    return Vue.axios
      .delete(resource, {data: params})
      .catch((error) => {
        throw new Error(`[RWV] ApiService ${error}`);
      });
  },

  massDelete(resource, params) {
    return Vue.axios
      .delete(resource, {params})
      .catch((error) => {
        throw new Error(`[RWV] ApiService ${error}`);
      });
  },

  download(resource, params) {
    return Vue.axios
      .get(resource, {
        responseType: 'blob',
        params: params
      });
  },

  upload(resource, formdata, progress) {
    return Vue.axios.post(resource, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progress
    });
  },

  updateFormData(resource, formdata, progress) {
    return Vue.axios.patch(resource, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progress
    });
  }

  // async get(resource, slug = '') {
  //   try {
  //     return Vue.axios
  //       .get(`${resource}/${slug}`);
  //   }
  //   catch (error) {
  //     throw new Error(`[RWV] ApiService ${error}`);
  //   }
  // }
};

export default ApiService;

export const InvitationService = {
  get(data) {
    return ApiService.get(`auth/invitation/accept?invitation_token=${data.token}`);
  },
  getJWT() {
    return ApiService.get('auth/current_user');
  },
  update(params) {
    return ApiService.update('auth/invitation', params);
  }
};

export const LoginService = {
  signIn (userData) {
    return ApiService.post('auth/sign_in', userData);
  },
  logOut(){
    delete Vue.axios.defaults.headers.common['Authorization'];
    return ApiService.delete('auth/sign_out');
  },
  forgotPassword(userData){
    return ApiService.post('auth/password.json', userData);
  },
  resetPassword(userData){
    return ApiService.put('auth/password.json', userData);
  },
  firstTime(userData){
    return ApiService.post('auth/first_time', userData);
  }
};

export const PracticeService = {
  getChildPracticeFor(practice_id, params){
    return ApiService.get(`${ApiService.prefixUrl}/${practice_id}/child_practices`, params);
  },
  getPracticeBy(id) {
    if(id){
      return ApiService.get(`${ApiService.prefixUrl}/${id}`);
    }else{
      return ApiService.get(`${ApiService.prefixUrl}`);
    }
  },
  getPractice(params) {
    return ApiService.get(`${ApiService.prefixUrl}`, params);
  },
  superAdminGetPractice(params) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}`, params);
  },
  addAddress(type, params) {
    return ApiService.post(`${ApiService.prefixUrl}${ApiService.practiceId}/${type}_address`, params);
  },
  updateAddress(type, params) {
    return ApiService.update(`${ApiService.prefixUrl}${ApiService.practiceId}/${type}_address`, params);
  },
  addPhysician(params) {
    return ApiService.post(`${ApiService.prefixUrl}${ApiService.practiceId}/physicians`, params);
  },
  updatePhysician(data, id) {
    return ApiService.update(`${ApiService.prefixUrl}${ApiService.practiceId}/physicians/${id}`, data);
  },
  addStaff(params) {
    return ApiService.post(`${ApiService.prefixUrl}${ApiService.practiceId}/staff`, params);
  },
  updateStaff(data, id) {
    return ApiService.update(`${ApiService.prefixUrl}${ApiService.practiceId}/staff/${id}`, data);
  },
  deleteStaff(id) {
    return ApiService.delete(`${ApiService.prefixUrl}${ApiService.practiceId}/staff/${id}`);
  },
  reassignRemovePhysician(data) {
    return ApiService.put(`${ApiService.prefixUrl}${ApiService.practiceId}/patients/reassign`, data);
  },
  removePhysician(id) {
    return ApiService.delete(`${ApiService.prefixUrl}${ApiService.practiceId}/physicians/${id}`);
  },
  reassignAdmin(data) {
    return ApiService.update(`${ApiService.prefixUrl}${ApiService.practiceId}/admin/reassign`, data);
  },
  updateAdminInformation(data) {
    return ApiService.put(`${ApiService.prefixUrl}${ApiService.practiceId}/admin`, data);
  },
  updatePractice(data) {
    return ApiService.update(`${ApiService.prefixUrl}${ApiService.practiceId}`, data);
  },
  updateFormData(formdata, progress) {
    return ApiService.updateFormData(`${ApiService.prefixUrl}${ApiService.practiceId}`, formdata, progress);
  }
};

export const PhysiciansService = {
  list(params) {
    return ApiService.get(`${ApiService.prefixUrl}/physicians`, params);
  },

  get(id) {
    return ApiService.get(`${ApiService.prefixUrl}/physicians/${id}`);
  }
};

export const AccountService = {
  updateAccountInformation(data){
    return ApiService.put('auth/current_user.json', data);
  }
};

export const PolicyService = {
  showPolicy(){
    return ApiService.get('/policy');
  },
  signPolicy() {
    return ApiService.post('/policy/sign');
  },
  alreadySigned() {
    return ApiService.post('/policy/already_signed');
  }
};

export const PatientsService = {
  get(params) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/patients`, params);
  },
  getPatient(params) {
    return ApiService.get(`${ApiService.prefixUrl}/patients/${params.id}`);
  },
  create(params, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients`, params);
  },
  update (params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}`, params);
  },
  updateRegimen(params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}/update_regimen`, params);
  },
  compleateRegistration(params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}/complete`, params);
  },
  async getPatientQuizResults(id) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/patients/${id}/quiz_results`);
  },
  async getRegimenProducts(regimen, id) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/patients/${id}/regimen_products?regimen=${regimen}`);
  },
  updateAddress (params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}/shipping_address`, params);
  },
  createAddress (params, id, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients/${id}/shipping_address`, params);
  },
  getReChargeShippingAddress(params, practiceId = '') {
    return ApiService.get(`${ApiService.prefixUrl}${practiceId}/patients/${params.id}/shipping_address/re_charge`);
  },
  createBillingAddress(params, id, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients/${id}/billing_address`, params);
  },
  updateBillingAddress(params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}/billing_address`, params);
  },
  updatePhysician (params) {
    return ApiService.update(`${ApiService.prefixUrl}/patients/update_physician`, params);
  },
  removePatients(params) {
    return ApiService.massDelete(`${ApiService.prefixUrl}/patients/remove`, params);
  },
  createPaymentMethod(params, id, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients/${id}/payment_method`, params);
  },
  updatePaymentMethod(params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}/payment_method`, params);
  },
  delete(id) {
    return ApiService.delete(`${ApiService.prefixUrl}/patients/${id}`);
  },
  sendRegimen(id, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients/${id}/regimen_send_email`);
  },
  listCsv(params) {
    return ApiService.get(`${ApiService.prefixUrl}/patients.csv`, params);
  },
  reassignPatient(id, params){
    return ApiService.update(`${ApiService.prefixUrl}/patients_reassign/${id}`, params);
  }
};

export const ProductService = {
  getProductMetaByHandle(handle) {
    return Vue.axios.request({
      url: `https://${shopifyURL}/api/2022-10/graphql.json`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': Vue.shopifyStorefrontApiKey,
        'Accept': 'application/json',
      },
      data: {
        query: `
          query {
            productByHandle(handle: "${handle}") {
              variants(first: 100) {
                edges {
                  node {
                    id
                    metafields(
                      identifiers: [
                        { namespace: "subscriptions", key: "discount_variant_id"},
                        { namespace: "subscriptions", key: "discount_variant_price"},
                      ]
                    ){
                      namespace
                      key
                      id
                      value
                    }
                  }
                }
              }
              metafields(
                identifiers: [
                  { namespace: "subscriptions", key: "shipping_interval_unit_type"},
                  { namespace: "subscriptions", key: "is_subscription_only"},
                  { namespace: "subscriptions", key: "shipping_interval_frequency"},
                  { namespace: "subscriptions", key: "discount_percentage"},
                  { namespace: "subscriptions", key: "discount_product_id"},
                  { namespace: "subscriptions", key: "subscription_id"},
                  { namespace: "subscriptions", key: "has_subscription"},
                  { namespace: "detail", key: "how_to_use"},
                  { namespace: "detail", key: "usage_instruction"},
                  { namespace: "detail", key: "for_physician"}
                ]
              ){
                namespace
                key
                id
                value
              }
            }
          }
        `
      }
    });
  },

  getSellingPlanByVariant(handle) {
    return Vue.axios.request({
      url: `https://${shopifyURL}/api/2022-10/graphql.json`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': Vue.shopifyStorefrontApiKey,
        'Accept': 'application/json',
      },
      data: {
        query: `{
          product(handle: "${handle}") {
            requiresSellingPlan
            sellingPlanGroups(first:1) {
              edges {
                node {
                  sellingPlans(first: 100) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      }
    });
  },

  createCheckoutFor(items) {
    const lineItemStrings = [];
    for (const item of items) {
      const lineItemStringParts = [
        `{merchandiseId: "${item.merchandiseId}"`,
        `quantity: ${item.quantity}`,
        item.sellingPlanId ? `sellingPlanId: "${item.sellingPlanId}"` : null,
        `attributes: [
          {
            key: "patient_id"
            value: "${item.patientId}"
          },
          {
            key: "user_id"
            value: "${item.physicianId}"
          }
        ]}`,
      ];
      const lineItemString = lineItemStringParts.filter(Boolean).join("\n");
      lineItemStrings.push(lineItemString);
    }
    const lines = lineItemStrings.join(",");
    return Vue.axios.request({
      url: `https://${shopifyURL}/api/2022-10/graphql.json`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': Vue.shopifyStorefrontApiKey,
        'Accept': 'application/json',
      },
      data: {
        query: `
          mutation cartCreate {
            cartCreate(input: {
              lines: [${lines}]
            }){
            cart {
              checkoutUrl
            }
          }
        }
      `
      }
    });
  }
};

export const TopPicksService = {
  get(params) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/physicians/top_picks`, params);
  },
  getPhysicianPicks(id) {
    return ApiService.get(`${ApiService.prefixUrl}/physicians/${id}/top_picks`);
  },
  create(id, params, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/physicians/${id}/top_picks`, params);
  },
  update(physicianId, productId, params, practiceId = '') {
    return ApiService.put(
      `${ApiService.prefixUrl}${practiceId}/physicians/${physicianId}/top_picks/${productId}`,
      params
    );
  },
  delete(physicianId, shopifyProductId, practiceId = '') {
    return ApiService.delete(`${ApiService.prefixUrl}${practiceId}/physicians/${physicianId}/top_picks/${shopifyProductId}`);
  }
};

export const RegimenService = {
  get(params) {
    return ApiService.get(`${ApiService.prefixUrl}/regimens`, params);
  },
  getRegimen(id) {
    return ApiService.get(`${ApiService.prefixUrl}/regimens/${id}`);
  },
  create(params, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/regimens`, params);
  },
  update (params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/regimens/${id}`, params);
  },
  delete(id, practiceId = '') {
    return ApiService.delete(`${ApiService.prefixUrl}${practiceId}/regimens/${id}`);
  },
  addProduct(params, id, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/regimens/${id}/products`, params);
  },
  updateProduct(params, regimenId, productId, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/regimens/${regimenId}/products/${productId}`, params);
  },
  deleteProduct(regimenId, productId, practiceId = '') {
    return ApiService.delete(`${ApiService.prefixUrl}${practiceId}/regimens/${regimenId}/products/${productId}`);
  },
  modifyRegimenProducts(params, regimenId, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/regimens/${regimenId}/products/modify_products`, params);
  }
};

export const CheckoutService = {
  getCharge(params, id, practiceId = '') {
    return ApiService.get(
      `${ApiService.prefixUrl}${practiceId}/patients/${id}/charge`, new URLSearchParams(params).toString()
    );
  },

  applyChargeDiscount(params, id, practiceId = '') {
    return ApiService.post(
      `${ApiService.prefixUrl}${practiceId}/patients/${id}/charge/discount`, params
    );
  },

  removeChargeDiscount(params, id, practiceId = '') {
    return ApiService.delete(
      `${ApiService.prefixUrl}${practiceId}/patients/${id}/charge/discount`, params
    );
  },

  process(params, id, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients/${id}/charge`, params);
  },
  complete(id, practiceId = '', params = {}) {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients/${id}/charge/complete`, params);
  },

  createCheckout(id, params, practiceId = '') {
    return ApiService.post(`${ApiService.prefixUrl}${practiceId}/patients/${id}/checkout`, params);
  },
  processCheckout(params, id, checkout_token, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}/checkout/${checkout_token}`, params);
  },
  completeCheckout(params, id, checkout_token, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/patients/${id}/checkout/${checkout_token}/complete`, params);
  }
};

export const OrdersService = {
  listCsv(params) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/orders.csv`, params);
  },

  list(params) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/orders`, params);
  },

  get(id) {
    return ApiService.get(`${ApiService.prefixUrl}/orders/${id}`);
  }
};


export const ReportService = {
  getPatientsInfo(params){
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/reports/patients`, params);
  },
  getSalesOverview(params){
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/reports/sales_overview`, params);
  },
  getOrdersPlaced(params){
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/reports/orders_placed`, params);
  },
  getSalesDetail(params){
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/reports/sales_details`, params);
  },
  getRoyaltiesDetail(params){
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/reports/royalties_details`, params);
  },
  getRoyaltiesDetailCSV(params){
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/reports/royalties_details.csv`, params);
  },
  getRoyaltiesOverview(params){
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/reports/royalties_overview`, params);
  }
};

export const ShopifyProductsFilter = {
  get(params) {
    return ApiService.get('practice/shopify_products_filter', params);
  }
};

export const SubscriptionService = {
  update(params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/subscriptions/${id}`, params);
  },
  cancel(params, id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/subscriptions/${id}/cancel`, params);
  },
  activate(id, practiceId = '') {
    return ApiService.update(`${ApiService.prefixUrl}${practiceId}/subscriptions/${id}/activate`);
  }
};

export const AlgoliaSearch = {
  get(indexType, params) {
    return ApiService.get(
      `${ApiService.prefixUrl}${ApiService.practiceId}/search/${indexType}`,
      params
    );
  }
};


export const ShopifyProductsAlgoliaSearch = {
  get(params) {
    return AlgoliaSearch.get('shopify_products', params);
  }
};

export const ProductLogsService = {
  getProductLogs(params) {
    return ApiService.get(`${ApiService.prefixUrl}${ApiService.practiceId}/shopify_product_change_logs`, params);
  }
};
